@charset "utf-8";
/*!
 * SNS brand color
 */
.fc-facebook  {color: rgba( 59, 89,152,1) !important;}/* #3b5998 */
.fc-twitter   {color: rgba( 85,172,238,1) !important;}/* #55acee */
.fc-instagram {color: rgba( 35, 31, 32,1) !important;}/* #3f729b */
.fc-youtube   {color: rgba(205, 32, 31,1) !important;}/* #cd201fs */

/*!
 * bx slider 
 */
.bx-wrapper { position: relative; margin: 0; padding: 0; background: transparent; }
.bx-wrapper .bx-viewport { transform: translate3d(0,0,0); }
.bx-wrapper .bx-loading { display: none; }