@charset "UTF-8";
/*! -----------------------------------------------
utility css 汎用クラス
--------------------------------------------------
1. Utility
   1-1.  display
   1-2.  grid
   1-3.  flex
   1-4.  float and clear
   1-5.  display - nfw
   1-6.  flex - nfw
   1-7.  position
   1-8.  opacity 
   1-9.  margin
   1-10. padding
   1-11. color
   1-12. font-size
   1-13. font-style
   1-14. text align
   1-15. line height
   1-16. word break
   1-17. link
   1-18. list
2. Vendor
*/
/*! -----------------------------------------------
 [1. Utility]
-------------------------------------------------- */
/*!
 * display
 */
.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

/*!
 * grid
 */
.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.gutters-0 {
  margin-left: 0;
  margin-right: 0;
}

.gutters-0.row > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.gutters-10 {
  margin-left: -5px;
  margin-right: -5px;
}

.gutters-10.row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.gutters-20 {
  margin-left: -10px;
  margin-right: -10px;
}

.gutters-20.row > [class*="col-"] {
  padding-right: 10px;
  padding-left: 10px;
}

.gutters-30 {
  margin-left: -15px;
  margin-right: -15px;
}

.gutters-30.row > [class*="col-"] {
  padding-right: 15px;
  padding-left: 15px;
}

.gutters-40 {
  margin-left: -20px;
  margin-right: -20px;
}

.gutters-40.row > [class*="col-"] {
  padding-right: 20px;
  padding-left: 20px;
}

.gutters-50 {
  margin-left: -25px;
  margin-right: -25px;
}

.gutters-50.row > [class*="col-"] {
  padding-right: 25px;
  padding-left: 25px;
}

.gutters-60 {
  margin-left: -30px;
  margin-right: -30px;
}

.gutters-60.row > [class*="col-"] {
  padding-right: 30px;
  padding-left: 30px;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.pull-0 {
  right: auto;
}

.pull-1 {
  right: 8.333333%;
}

.pull-2 {
  right: 16.666667%;
}

.pull-3 {
  right: 25%;
}

.pull-4 {
  right: 33.333333%;
}

.pull-5 {
  right: 41.666667%;
}

.pull-6 {
  right: 50%;
}

.pull-7 {
  right: 58.333333%;
}

.pull-8 {
  right: 66.666667%;
}

.pull-9 {
  right: 75%;
}

.pull-10 {
  right: 83.333333%;
}

.pull-11 {
  right: 91.666667%;
}

.pull-12 {
  right: 100%;
}

.push-0 {
  left: auto;
}

.push-1 {
  left: 8.333333%;
}

.push-2 {
  left: 16.666667%;
}

.push-3 {
  left: 25%;
}

.push-4 {
  left: 33.333333%;
}

.push-5 {
  left: 41.666667%;
}

.push-6 {
  left: 50%;
}

.push-7 {
  left: 58.333333%;
}

.push-8 {
  left: 66.666667%;
}

.push-9 {
  left: 75%;
}

.push-10 {
  left: 83.333333%;
}

.push-11 {
  left: 91.666667%;
}

.push-12 {
  left: 100%;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .pull-sm-0 {
    right: auto;
  }
  .pull-sm-1 {
    right: 8.333333%;
  }
  .pull-sm-2 {
    right: 16.666667%;
  }
  .pull-sm-3 {
    right: 25%;
  }
  .pull-sm-4 {
    right: 33.333333%;
  }
  .pull-sm-5 {
    right: 41.666667%;
  }
  .pull-sm-6 {
    right: 50%;
  }
  .pull-sm-7 {
    right: 58.333333%;
  }
  .pull-sm-8 {
    right: 66.666667%;
  }
  .pull-sm-9 {
    right: 75%;
  }
  .pull-sm-10 {
    right: 83.333333%;
  }
  .pull-sm-11 {
    right: 91.666667%;
  }
  .pull-sm-12 {
    right: 100%;
  }
  .push-sm-0 {
    left: auto;
  }
  .push-sm-1 {
    left: 8.333333%;
  }
  .push-sm-2 {
    left: 16.666667%;
  }
  .push-sm-3 {
    left: 25%;
  }
  .push-sm-4 {
    left: 33.333333%;
  }
  .push-sm-5 {
    left: 41.666667%;
  }
  .push-sm-6 {
    left: 50%;
  }
  .push-sm-7 {
    left: 58.333333%;
  }
  .push-sm-8 {
    left: 66.666667%;
  }
  .push-sm-9 {
    left: 75%;
  }
  .push-sm-10 {
    left: 83.333333%;
  }
  .push-sm-11 {
    left: 91.666667%;
  }
  .push-sm-12 {
    left: 100%;
  }
  .offset-sm-0 {
    margin-left: 0%;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .pull-md-0 {
    right: auto;
  }
  .pull-md-1 {
    right: 8.333333%;
  }
  .pull-md-2 {
    right: 16.666667%;
  }
  .pull-md-3 {
    right: 25%;
  }
  .pull-md-4 {
    right: 33.333333%;
  }
  .pull-md-5 {
    right: 41.666667%;
  }
  .pull-md-6 {
    right: 50%;
  }
  .pull-md-7 {
    right: 58.333333%;
  }
  .pull-md-8 {
    right: 66.666667%;
  }
  .pull-md-9 {
    right: 75%;
  }
  .pull-md-10 {
    right: 83.333333%;
  }
  .pull-md-11 {
    right: 91.666667%;
  }
  .pull-md-12 {
    right: 100%;
  }
  .push-md-0 {
    left: auto;
  }
  .push-md-1 {
    left: 8.333333%;
  }
  .push-md-2 {
    left: 16.666667%;
  }
  .push-md-3 {
    left: 25%;
  }
  .push-md-4 {
    left: 33.333333%;
  }
  .push-md-5 {
    left: 41.666667%;
  }
  .push-md-6 {
    left: 50%;
  }
  .push-md-7 {
    left: 58.333333%;
  }
  .push-md-8 {
    left: 66.666667%;
  }
  .push-md-9 {
    left: 75%;
  }
  .push-md-10 {
    left: 83.333333%;
  }
  .push-md-11 {
    left: 91.666667%;
  }
  .push-md-12 {
    left: 100%;
  }
  .offset-md-0 {
    margin-left: 0%;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .pull-lg-0 {
    right: auto;
  }
  .pull-lg-1 {
    right: 8.333333%;
  }
  .pull-lg-2 {
    right: 16.666667%;
  }
  .pull-lg-3 {
    right: 25%;
  }
  .pull-lg-4 {
    right: 33.333333%;
  }
  .pull-lg-5 {
    right: 41.666667%;
  }
  .pull-lg-6 {
    right: 50%;
  }
  .pull-lg-7 {
    right: 58.333333%;
  }
  .pull-lg-8 {
    right: 66.666667%;
  }
  .pull-lg-9 {
    right: 75%;
  }
  .pull-lg-10 {
    right: 83.333333%;
  }
  .pull-lg-11 {
    right: 91.666667%;
  }
  .pull-lg-12 {
    right: 100%;
  }
  .push-lg-0 {
    left: auto;
  }
  .push-lg-1 {
    left: 8.333333%;
  }
  .push-lg-2 {
    left: 16.666667%;
  }
  .push-lg-3 {
    left: 25%;
  }
  .push-lg-4 {
    left: 33.333333%;
  }
  .push-lg-5 {
    left: 41.666667%;
  }
  .push-lg-6 {
    left: 50%;
  }
  .push-lg-7 {
    left: 58.333333%;
  }
  .push-lg-8 {
    left: 66.666667%;
  }
  .push-lg-9 {
    left: 75%;
  }
  .push-lg-10 {
    left: 83.333333%;
  }
  .push-lg-11 {
    left: 91.666667%;
  }
  .push-lg-12 {
    left: 100%;
  }
  .offset-lg-0 {
    margin-left: 0%;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .pull-xl-0 {
    right: auto;
  }
  .pull-xl-1 {
    right: 8.333333%;
  }
  .pull-xl-2 {
    right: 16.666667%;
  }
  .pull-xl-3 {
    right: 25%;
  }
  .pull-xl-4 {
    right: 33.333333%;
  }
  .pull-xl-5 {
    right: 41.666667%;
  }
  .pull-xl-6 {
    right: 50%;
  }
  .pull-xl-7 {
    right: 58.333333%;
  }
  .pull-xl-8 {
    right: 66.666667%;
  }
  .pull-xl-9 {
    right: 75%;
  }
  .pull-xl-10 {
    right: 83.333333%;
  }
  .pull-xl-11 {
    right: 91.666667%;
  }
  .pull-xl-12 {
    right: 100%;
  }
  .push-xl-0 {
    left: auto;
  }
  .push-xl-1 {
    left: 8.333333%;
  }
  .push-xl-2 {
    left: 16.666667%;
  }
  .push-xl-3 {
    left: 25%;
  }
  .push-xl-4 {
    left: 33.333333%;
  }
  .push-xl-5 {
    left: 41.666667%;
  }
  .push-xl-6 {
    left: 50%;
  }
  .push-xl-7 {
    left: 58.333333%;
  }
  .push-xl-8 {
    left: 66.666667%;
  }
  .push-xl-9 {
    left: 75%;
  }
  .push-xl-10 {
    left: 83.333333%;
  }
  .push-xl-11 {
    left: 91.666667%;
  }
  .push-xl-12 {
    left: 100%;
  }
  .offset-xl-0 {
    margin-left: 0%;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

/*!
 * flex
 */
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

/*!
 * for Voice Browser
 */
.hidden {
  position: absolute;
  overflow: hidden;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
}

/*!
 * float_clearFix 
 */
.clearfix {
  *zoom: 1;
  /* display: flow-root; */
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

/*!
 * float and clear
 */
.u-fl-n {
  float: none !important;
}

.u-fl-l {
  float: left !important;
}

.u-fl-r {
  float: right !important;
}

.u-cl_n {
  clear: none !important;
}

.u-cl_l {
  clear: left !important;
}

.u-cl_r {
  clear: right !important;
}

.u-cl_b {
  clear: both !important;
}

/*!
 * display - nfw
 */
.u-d-n {
  display: none !important;
}

.u-d-b {
  display: block !important;
}

.u-d-f {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.u-d-if {
  display: -webkit-inline-flex !important;
  display: inline-flex !important;
}

.u-d-i {
  display: inline !important;
}

.u-d-ib {
  display: inline-block !important;
}

.u-d-li {
  display: list-item !important;
}

.u-d-ri {
  display: run-in !important;
}

.u-d-cp {
  display: compact !important;
}

.u-d-tb {
  display: table !important;
}

.u-d-itb {
  display: inline-table !important;
}

.u-d-tbcp {
  display: table-caption !important;
}

.u-d-tbcl {
  display: table-column !important;
}

.u-d-tbclg {
  display: table-column-group !important;
}

.u-d-tbhg {
  display: table-header-group !important;
}

.u-d-tbfg {
  display: table-footer-group !important;
}

.u-d-tbr {
  display: table-row !important;
}

.u-d-tbrg {
  display: table-row-group !important;
}

.u-d-tbc {
  display: table-cell !important;
}

.u-d-rb {
  display: ruby !important;
}

.u-d-rbb {
  display: ruby-base !important;
}

.u-d-rbbg {
  display: ruby-base-group !important;
}

.u-d-rbt {
  display: ruby-text !important;
}

.u-d-rbtg {
  display: ruby-text-group !important;
}

/*!
 * flex - nfw
 */
.fxd-r {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.fxd-rr {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.fxd-c {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.fxd-cr {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.jc-fs {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.jc-fe {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.jc-c {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.jc-sb {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.jc-sa {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.ac-fs {
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.ac-fe {
  -ms-flex-line-pack: end;
  align-content: flex-end;
}

.ac-c {
  -ms-flex-line-pack: center;
  align-content: center;
}

.ac-sb {
  -ms-flex-line-pack: justify;
  align-content: space-between;
}

.ac-sa {
  -ms-flex-line-pack: distribute;
  align-content: space-around;
}

.ac-s {
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

.ai-fs {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.ai-fe {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.ai-s {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.ai-c {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ai-b {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

/*!
 * position 
 */
.u-pos-st {
  position: static;
}

.u-pos-rl {
  position: relative;
}

.u-pos-ab {
  position: absolute;
}

.u-pos-fx {
  position: fixed;
}

.u-pos-si {
  position: sticky;
}

.u-pos-ih {
  position: inherit;
}

.u-pos-in {
  position: initial;
}

.u-pos-un {
  position: unset;
}

/*!
 * opacity 
 */
.u-op100 {
  opacity: 1 !important;
}

.u-op00 {
  opacity: 1;
}

.u-op00:hover, .u-op00:active {
  opacity: 0;
}

.u-op01 {
  opacity: 1;
}

.u-op01:hover, .u-op01:active {
  opacity: 0.1;
}

.u-op02 {
  opacity: 1;
}

.u-op02:hover, .u-op02:active {
  opacity: 0.2;
}

.u-op03 {
  opacity: 1;
}

.u-op03:hover, .u-op03:active {
  opacity: 0.3;
}

.u-op04 {
  opacity: 1;
}

.u-op04:hover, .u-op04:active {
  opacity: 0.4;
}

.u-op05 {
  opacity: 1;
}

.u-op05:hover, .u-op05:active {
  opacity: 0.5;
}

.u-op06 {
  opacity: 1;
}

.u-op06:hover, .u-op06:active {
  opacity: 0.6;
}

.u-op07 {
  opacity: 1;
}

.u-op07:hover, .u-op07:active {
  opacity: 0.7;
}

.u-op08 {
  opacity: 1;
}

.u-op08:hover, .u-op08:active {
  opacity: 0.8;
}

.u-op09 {
  opacity: 1;
}

.u-op09:hover, .u-op09:active {
  opacity: 0.9;
}

/*!
 * margin - t,r,b,l
 */
.u-mt0 {
  margin-top: 0px !important;
}

.u-mr0 {
  margin-right: 0px !important;
}

.u-mb0 {
  margin-bottom: 0px !important;
}

.u-ml0 {
  margin-left: 0px !important;
}

.u-mt5 {
  margin-top: 5px !important;
}

.u-mr5 {
  margin-right: 5px !important;
}

.u-mb5 {
  margin-bottom: 5px !important;
}

.u-ml5 {
  margin-left: 5px !important;
}

.u-mt10 {
  margin-top: 10px !important;
}

.u-mr10 {
  margin-right: 10px !important;
}

.u-mb10 {
  margin-bottom: 10px !important;
}

.u-ml10 {
  margin-left: 10px !important;
}

.u-mt15 {
  margin-top: 15px !important;
}

.u-mr15 {
  margin-right: 15px !important;
}

.u-mb15 {
  margin-bottom: 15px !important;
}

.u-ml15 {
  margin-left: 15px !important;
}

.u-mt20 {
  margin-top: 20px !important;
}

.u-mr20 {
  margin-right: 20px !important;
}

.u-mb20 {
  margin-bottom: 20px !important;
}

.u-ml20 {
  margin-left: 20px !important;
}

.u-mt25 {
  margin-top: 25px !important;
}

.u-mr25 {
  margin-right: 25px !important;
}

.u-mb25 {
  margin-bottom: 25px !important;
}

.u-ml25 {
  margin-left: 25px !important;
}

.u-mt30 {
  margin-top: 30px !important;
}

.u-mr30 {
  margin-right: 30px !important;
}

.u-mb30 {
  margin-bottom: 30px !important;
}

.u-ml30 {
  margin-left: 30px !important;
}

.u-mt35 {
  margin-top: 35px !important;
}

.u-mr35 {
  margin-right: 35px !important;
}

.u-mb35 {
  margin-bottom: 35px !important;
}

.u-ml35 {
  margin-left: 35px !important;
}

.u-mt40 {
  margin-top: 40px !important;
}

.u-mr40 {
  margin-right: 40px !important;
}

.u-mb40 {
  margin-bottom: 40px !important;
}

.u-ml40 {
  margin-left: 40px !important;
}

.u-mt45 {
  margin-top: 45px !important;
}

.u-mr45 {
  margin-right: 45px !important;
}

.u-mb45 {
  margin-bottom: 45px !important;
}

.u-ml45 {
  margin-left: 45px !important;
}

.u-mt50 {
  margin-top: 50px !important;
}

.u-mr50 {
  margin-right: 50px !important;
}

.u-mb50 {
  margin-bottom: 50px !important;
}

.u-ml50 {
  margin-left: 50px !important;
}

/*!
 * padding - t,r,b,l,v,h
 */
.u-pt0 {
  padding-top: 0px !important;
}

.u-pr0 {
  padding-right: 0px !important;
}

.u-pb0 {
  padding-bottom: 0px !important;
}

.u-pl0 {
  padding-left: 0px !important;
}

.u-pv0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.u-ph0 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.u-pa0 {
  padding: 0px !important;
}

.u-pt5 {
  padding-top: 5px !important;
}

.u-pr5 {
  padding-right: 5px !important;
}

.u-pb5 {
  padding-bottom: 5px !important;
}

.u-pl5 {
  padding-left: 5px !important;
}

.u-pv5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.u-ph5 {
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.u-pa5 {
  padding: 5px !important;
}

.u-pt10 {
  padding-top: 10px !important;
}

.u-pr10 {
  padding-right: 10px !important;
}

.u-pb10 {
  padding-bottom: 10px !important;
}

.u-pl10 {
  padding-left: 10px !important;
}

.u-pv10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.u-ph10 {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.u-pa10 {
  padding: 10px !important;
}

.u-pt15 {
  padding-top: 15px !important;
}

.u-pr15 {
  padding-right: 15px !important;
}

.u-pb15 {
  padding-bottom: 15px !important;
}

.u-pl15 {
  padding-left: 15px !important;
}

.u-pv15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.u-ph15 {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.u-pa15 {
  padding: 15px !important;
}

.u-pt20 {
  padding-top: 20px !important;
}

.u-pr20 {
  padding-right: 20px !important;
}

.u-pb20 {
  padding-bottom: 20px !important;
}

.u-pl20 {
  padding-left: 20px !important;
}

.u-pv20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.u-ph20 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.u-pa20 {
  padding: 20px !important;
}

.u-pt25 {
  padding-top: 25px !important;
}

.u-pr25 {
  padding-right: 25px !important;
}

.u-pb25 {
  padding-bottom: 25px !important;
}

.u-pl25 {
  padding-left: 25px !important;
}

.u-pv25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.u-ph25 {
  padding-right: 25px !important;
  padding-left: 25px !important;
}

.u-pa25 {
  padding: 25px !important;
}

.u-pt30 {
  padding-top: 30px !important;
}

.u-pr30 {
  padding-right: 30px !important;
}

.u-pb30 {
  padding-bottom: 30px !important;
}

.u-pl30 {
  padding-left: 30px !important;
}

.u-pv30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.u-ph30 {
  padding-right: 30px !important;
  padding-left: 30px !important;
}

.u-pa30 {
  padding: 30px !important;
}

.u-pt35 {
  padding-top: 35px !important;
}

.u-pr35 {
  padding-right: 35px !important;
}

.u-pb35 {
  padding-bottom: 35px !important;
}

.u-pl35 {
  padding-left: 35px !important;
}

.u-pv35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.u-ph35 {
  padding-right: 35px !important;
  padding-left: 35px !important;
}

.u-pa35 {
  padding: 35px !important;
}

.u-pt40 {
  padding-top: 40px !important;
}

.u-pr40 {
  padding-right: 40px !important;
}

.u-pb40 {
  padding-bottom: 40px !important;
}

.u-pl40 {
  padding-left: 40px !important;
}

.u-pv40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.u-ph40 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

.u-pa40 {
  padding: 40px !important;
}

.u-pt45 {
  padding-top: 45px !important;
}

.u-pr45 {
  padding-right: 45px !important;
}

.u-pb45 {
  padding-bottom: 45px !important;
}

.u-pl45 {
  padding-left: 45px !important;
}

.u-pv45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.u-ph45 {
  padding-right: 45px !important;
  padding-left: 45px !important;
}

.u-pa45 {
  padding: 45px !important;
}

.u-pt50 {
  padding-top: 50px !important;
}

.u-pr50 {
  padding-right: 50px !important;
}

.u-pb50 {
  padding-bottom: 50px !important;
}

.u-pl50 {
  padding-left: 50px !important;
}

.u-pv50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.u-ph50 {
  padding-right: 50px !important;
  padding-left: 50px !important;
}

.u-pa50 {
  padding: 50px !important;
}

/*!
 * color
 */
.u-fc-white {
  color: #fff !important;
}

.u-fc-red {
  color: #f00 !important;
}

.u-fc-cred {
  color: #c00 !important;
}

.u-fc-dred {
  color: #d00 !important;
}

.u-fc-9red {
  color: #900 !important;
}

.u-fc-black {
  color: #111 !important;
}

.u-fc-dark {
  color: #666 !important;
}

.u-fc-gray {
  color: #999 !important;
}

.u-fc-blue {
  color: #006 !important;
}

/*!
 * font-size
 */
.u-fs8 {
  font-size: 0.8rem !important;
}

.u-fs9 {
  font-size: 0.9rem !important;
}

.u-fs10 {
  font-size: 1rem !important;
}

.u-fs11 {
  font-size: 1.1rem !important;
}

.u-fs12 {
  font-size: 1.2rem !important;
}

.u-fs13 {
  font-size: 1.3rem !important;
}

.u-fs14 {
  font-size: 1.4rem !important;
}

.u-fs15 {
  font-size: 1.5rem !important;
}

.u-fs16 {
  font-size: 1.6rem !important;
}

.u-fs17 {
  font-size: 1.7rem !important;
}

.u-fs18 {
  font-size: 1.8rem !important;
}

.u-fs19 {
  font-size: 1.9rem !important;
}

.u-fs20 {
  font-size: 2rem !important;
}

.u-fs21 {
  font-size: 2.1rem !important;
}

.u-fs22 {
  font-size: 2.2rem !important;
}

.u-fs23 {
  font-size: 2.3rem !important;
}

.u-fs24 {
  font-size: 2.4rem !important;
}

.u-fs25 {
  font-size: 2.5rem !important;
}

.u-fs26 {
  font-size: 2.6rem !important;
}

.u-fs27 {
  font-size: 2.7rem !important;
}

.u-fs28 {
  font-size: 2.8rem !important;
}

.u-fs29 {
  font-size: 2.9rem !important;
}

.u-fs30 {
  font-size: 3rem !important;
}

.u-fs31 {
  font-size: 3.1rem !important;
}

.u-fs32 {
  font-size: 3.2rem !important;
}

.u-fs33 {
  font-size: 3.3rem !important;
}

.u-fs34 {
  font-size: 3.4rem !important;
}

.u-fs35 {
  font-size: 3.5rem !important;
}

.u-fs36 {
  font-size: 3.6rem !important;
}

.u-fs37 {
  font-size: 3.7rem !important;
}

.u-fs38 {
  font-size: 3.8rem !important;
}

.u-fs39 {
  font-size: 3.9rem !important;
}

.u-fs40 {
  font-size: 4rem !important;
}

.u-fs41 {
  font-size: 4.1rem !important;
}

.u-fs42 {
  font-size: 4.2rem !important;
}

.u-fs43 {
  font-size: 4.3rem !important;
}

.u-fs44 {
  font-size: 4.4rem !important;
}

.u-fs45 {
  font-size: 4.5rem !important;
}

.u-fs46 {
  font-size: 4.6rem !important;
}

.u-fs47 {
  font-size: 4.7rem !important;
}

.u-fs48 {
  font-size: 4.8rem !important;
}

.u-fsxxs {
  font-size: xx-small;
}

.u-fsxs {
  font-size: x-small;
}

.u-fss {
  font-size: small;
}

.u-fsm {
  font-size: medium;
}

.u-fsl {
  font-size: large;
}

.u-fsxl {
  font-size: x-large;
}

.u-fsxxl {
  font-size: xx-large;
}

.u-tim00 {
  text-indent: 0em !important;
}

.u-tim01 {
  text-indent: -0.1em !important;
}

.u-tim02 {
  text-indent: -0.2em !important;
}

.u-tim03 {
  text-indent: -0.3em !important;
}

.u-tim04 {
  text-indent: -0.4em !important;
}

.u-tim05 {
  text-indent: -0.5em !important;
}

.u-tim06 {
  text-indent: -0.6em !important;
}

.u-tim07 {
  text-indent: -0.7em !important;
}

.u-tim08 {
  text-indent: -0.8em !important;
}

.u-tim09 {
  text-indent: -0.9em !important;
}

.u-tim10 {
  text-indent: -1em !important;
}

.u-tim11 {
  text-indent: -1.1em !important;
}

.u-tim12 {
  text-indent: -1.2em !important;
}

.u-tim13 {
  text-indent: -1.3em !important;
}

.u-tim14 {
  text-indent: -1.4em !important;
}

.u-tim15 {
  text-indent: -1.5em !important;
}

.u-tim16 {
  text-indent: -1.6em !important;
}

.u-tim17 {
  text-indent: -1.7em !important;
}

.u-tim18 {
  text-indent: -1.8em !important;
}

.u-tim19 {
  text-indent: -1.9em !important;
}

.u-tim20 {
  text-indent: -2em !important;
}

/*!
 * font-style
 */
.u-fw-n {
  font-weight: normal !important;
}

.u-fw-b {
  font-weight: bold !important;
}

.u-fw-br {
  font-weight: bolder !important;
}

.u-fw-lr {
  font-weight: lighter !important;
}

.u-fw-i {
  font-weight: inherit !important;
}

.u-fw100 {
  font-weight: 100 !important;
}

.u-fw200 {
  font-weight: 200 !important;
}

.u-fw300 {
  font-weight: 300 !important;
}

.u-fw400 {
  font-weight: 400 !important;
}

.u-fw500 {
  font-weight: 500 !important;
}

.u-fw600 {
  font-weight: 600 !important;
}

.u-fw700 {
  font-weight: 700 !important;
}

.u-fw800 {
  font-weight: 800 !important;
}

.u-fw900 {
  font-weight: 900 !important;
}

.u-fs-n {
  font-style: normal !important;
}

.u-fs-o {
  font-style: oblique !important;
}

.u-fs-i {
  font-style: italic !important;
}

/*!
 * text align
 */
.u-ta-l {
  text-align: left !important;
}

.u-ta-c {
  text-align: center !important;
}

.u-ta-r {
  text-align: right !important;
}

.u-ta-j {
  text-align: justify !important;
}

.u-va-sup {
  vertical-align: super !important;
}

.u-va-t {
  vertical-align: top !important;
}

.u-va-tt {
  vertical-align: text-top !important;
}

.u-va-m {
  vertical-align: middle !important;
}

.u-va-bl {
  vertical-align: baseline !important;
}

.u-va-tb {
  vertical-align: text-bottom !important;
}

.u-va-b {
  vertical-align: bottom !important;
}

.u-va-sub {
  vertical-align: sub !important;
}

.ta-l {
  text-align: left;
}

.ta-c {
  text-align: center;
}

.ta-r {
  text-align: right;
}

.ta-j {
  text-align: justify;
}

@media (max-width: 575px) {
  .ta-xs-l {
    text-align: left;
  }
  .ta-xs-c {
    text-align: center;
  }
  .ta-xs-r {
    text-align: right;
  }
  .ta-xs-j {
    text-align: justify;
  }
}

@media (min-width: 576px) {
  .ta-sm-l {
    text-align: left;
  }
  .ta-sm-c {
    text-align: center;
  }
  .ta-sm-r {
    text-align: right;
  }
  .ta-sm-j {
    text-align: justify;
  }
}

@media (min-width: 768px) {
  .ta-md-l {
    text-align: left;
  }
  .ta-md-c {
    text-align: center;
  }
  .ta-md-r {
    text-align: right;
  }
  .ta-md-j {
    text-align: justify;
  }
}

/*!
 * line height
 */
.u-lh10 {
  line-height: 1;
}

.u-lh11 {
  line-height: 1.1;
}

.u-lh12 {
  line-height: 1.2;
}

.u-lh13 {
  line-height: 1.3;
}

.u-lh14 {
  line-height: 1.4;
}

.u-lh15 {
  line-height: 1.5;
}

.u-lh16 {
  line-height: 1.6;
}

.u-lh17 {
  line-height: 1.7;
}

.u-lh18 {
  line-height: 1.8;
}

.u-lh19 {
  line-height: 1.9;
}

.u-lh20 {
  line-height: 2;
}

.u-lh21 {
  line-height: 2.1;
}

.u-lh22 {
  line-height: 2.2;
}

.u-lh23 {
  line-height: 2.3;
}

.u-lh24 {
  line-height: 2.4;
}

.u-lh25 {
  line-height: 2.5;
}

.u-lh26 {
  line-height: 2.6;
}

.u-lh27 {
  line-height: 2.7;
}

.u-lh28 {
  line-height: 2.8;
}

.u-lh29 {
  line-height: 2.9;
}

.u-lh30 {
  line-height: 3;
}

/*!
 * word break
 */
.u-wobr-n {
  word-break: normal !important;
}

.u-wobr-k {
  word-break: keep-all !important;
  word-wrap: normal !important;
}

.u-wobr-ba {
  word-break: break-all !important;
  word-wrap: break-word !important;
}

.u-whsp-n {
  white-space: normal !important;
}

.u-whsp-p {
  white-space: pre !important;
}

.u-whsp-nw {
  white-space: nowrap !important;
}

/*!
 * link
 */
.u-txd-n {
  text-decoration: none !important;
}

.u-txd-o {
  text-decoration: overline !important;
}

.u-txd-u {
  text-decoration: underline !important;
}

.u-txd-l {
  text-decoration: line-through !important;
}

.u-txd-b {
  text-decoration: blink !important;
}

/*!
 * list
 */
li img, li a, li a img {
  vertical-align: bottom;
}

.u-list-d {
  padding-left: 1.5em;
}

.u-list-d > * {
  list-style-type: disc;
}

.u-list-c {
  padding-left: 1.5em;
}

.u-list-c > * {
  list-style-type: circle;
}

.u-list-s {
  padding-left: 1.5em;
}

.u-list-s > * {
  list-style-type: square;
}

.u-list-dc {
  padding-left: 1.5em;
}

.u-list-dc > * {
  list-style-type: decimal;
}

.u-list-la {
  padding-left: 1.8em;
}

.u-list-la > * {
  list-style-type: lower-alpha;
}

.u-list-at {
  padding-left: 1em;
}

.u-list-at > * {
  text-indent: -1em;
}

.u-list-x1 {
  padding-left: 1.2em;
}

.u-list-x1 > * {
  text-indent: -1.2em;
}

/*!
 * SNS brand color
 */
.fc-facebook {
  color: #3b5998 !important;
}

/* #3b5998 */
.fc-twitter {
  color: #55acee !important;
}

/* #55acee */
.fc-instagram {
  color: #231f20 !important;
}

/* #3f729b */
.fc-youtube {
  color: #cd201f !important;
}

/* #cd201fs */
/*!
 * bx slider 
 */
.bx-wrapper {
  position: relative;
  margin: 0;
  padding: 0;
  background: transparent;
}

.bx-wrapper .bx-viewport {
  transform: translate3d(0, 0, 0);
}

.bx-wrapper .bx-loading {
  display: none;
}

/*!

*/
/*! -----------------------------------------------
 [2. Vendor]
-------------------------------------------------- */
/*!
 * SNS brand color
 */
.fc-facebook {
  color: #3b5998 !important;
}

/* #3b5998 */
.fc-twitter {
  color: #55acee !important;
}

/* #55acee */
.fc-instagram {
  color: #231f20 !important;
}

/* #3f729b */
.fc-youtube {
  color: #cd201f !important;
}

/* #cd201fs */
/*!
 * bx slider 
 */
.bx-wrapper {
  position: relative;
  margin: 0;
  padding: 0;
  background: transparent;
}

.bx-wrapper .bx-viewport {
  transform: translate3d(0, 0, 0);
}

.bx-wrapper .bx-loading {
  display: none;
}
